.mat-mdc-form-field + .mat-mdc-form-field {
  margin-top: 5px;
}

// legacy css
// .mat-mdc-checkbox .mat-mdc-checkbox-layout {
//   white-space: normal;
// }

.mat-mdc-form-field {
  display: block !important;

  .mat-mdc-form-field-hint-wrapper,
  .mat-mdc-form-field-error-wrapper {
    padding: 0;
  }
}
