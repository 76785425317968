.wizard {
  .mat-stepper-horizontal,
  .mat-stepper-vertical {
    background: transparent;

    .mat-horizontal-content-container {
      overflow: visible;
    }

    .mat-step-text-label {
      text-wrap: wrap;
    }

    .mat-step-label-selected {
      font-size: 14px;
    }

    .mat-step-icon {
      width: 36px;
      height: 36px;
    }

    .mat-step-icon:not(.mat-step-icon-selected) {
      background: rgba(255, 255, 255, 0.2);
    }

    .mat-step-label.mat-step-label-active.mat-step-label-selected {
      color: darken($primary-color, 10%);
    }

    .mat-step-label:not(.mat-step-label-selected) {
      color: rgba(255, 255, 255, 0.8);
    }
  }

  .mat-horizontal-stepper-content {
    transform: none !important;
  }

  .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:first-child)::before,
  [dir='rtl'] .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:last-child)::before,
  .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:last-child)::after,
  [dir='rtl'] .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:first-child)::after,
  .mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
    top: 42px !important;
  }
}

.mat-step-header.cdk-keyboard-focused,
.mat-step-header.cdk-program-focused,
.mat-step-header:hover {
  background-color: transparent;
}
