a {
  transition: all 0.5s;
  color: $primary-color;

  &:hover,
  &:focus,
  &:active {
    color: darken($primary-color, 15%);
  }

  &.white {
    text-decoration: underline;

    &:hover,
    &:focus,
    &:active {
      text-decoration: underline;
      color: rgba(white, 0.7);
    }
  }
}

span.icon {
  .ng-fa-icon {
    margin-right: 5px;
  }
}

strong {
  font-weight: 500;
}
