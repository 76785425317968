@use '@angular/material' as mat;
@import '@angular/material/prebuilt-themes/pink-bluegrey.css';

@include mat.core();

$groomoteka-primary: mat.define-palette(mat.$cyan-palette, 700);
$groomoteka-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

$groomoteka-theme: mat.define-light-theme(
  (
    color: (
      primary: $groomoteka-primary,
      accent: $groomoteka-accent,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);

@include mat.core-theme($groomoteka-theme);
@include mat.all-component-themes($groomoteka-theme);

// overwrite bootstrap varaibles
@import 'styles/variables';

// bootstrap
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/reboot';
@import 'bootstrap/scss/grid';
@import 'bootstrap/scss/utilities';

// custom styles
@import 'styles/index.scss';
