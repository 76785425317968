@use '@angular/material' as mat;

.white {
  color: white;

  a {
    color: white;
    text-decoration: underline;

    &:hover,
    &:focus,
    &:active {
      color: rgba(white, 0.7);
    }
  }
}

.color-container {
  display: inline-block;
  padding: 2px 5px;
  font-size: 0.8rem;
}

ul.consents {
  list-style: none;
  padding: 0;

  li {
    .icon-consent {
      margin-right: 10px;
    }

    span {
      opacity: 0.8;
    }
  }

  li {
    &.yes .icon-consent {
      color: $green-color;
    }

    &.no .icon-consent {
      color: $error-color;
    }
  }
}

.mat-drawer-inner-container {
  overflow: hidden !important;
}
