.fc-daygrid .fc-daygrid-day-frame {
  &:hover {
    background: rgba($primary-color, 0.1);
  }
}

.fc-day-sat {
  background: rgba($primary-color, 0.05);
}

.fc-day-sun {
  background: rgba($primary-color, 0.05);
}

.fc-v-event {
  border-left-width: 5px !important;
  border-left-style: solid;
  padding-left: 5px;
  border-right: 0 !important;
  border-top: 0 !important;
  border-bottom: 0 !important;
}

@media (max-width: 768px) {
  .fc-header-toolbar.fc-toolbar {
    flex-direction: column;
  }

  .fc-toolbar-chunk {
    margin-bottom: 0.5rem;
  }
}
