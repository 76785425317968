.mat-mdc-snack-bar-container {
  &.mat-mdc-warn {
    background: $error-color;
    color: white;
  }

  &.mat-mdc-primary {
    background: $green-color;
    color: white;
  }
}
