$logo: rgb(44, 159, 177); //#2C9FB1
$primary-color: #00838f;
$primary-color-light: #81b9bf;
$primary-color-material: #0097a7;

$error-color: #db2e16;

$light-grey-color: #f9f9f9;
$light-grey-color2: #fafafa;
$grey-color: #e9e9e9;
$grey-color2: #eee;
$grey-color3: #bbb;

$green-color: #23b074;

$female-color: #c44381;
$male-color: #2877a8;
