html,
body {
  min-height: 100%;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.mat-mdc-drawer-container {
  background: $light-grey-color2;
  background: -webkit-linear-gradient(to bottom, $light-grey-color, $light-grey-color2);
  background: linear-gradient(to bottom, $light-grey-color, $light-grey-color2);
}

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;

  .main-container {
    flex: 1 0 auto;
  }

  .footer {
    flex-shrink: 0;
  }
}

.authenticated-layout {
  padding-top: 64px;
  min-height: 100vh;

  .mat-toolbar {
    position: fixed !important;
    z-index: 9 !important;
    top: 0;
  }

  .footer {
    padding: 15px 20px;
    background: $grey-color;
  }

  .mat-mdc-card {
    padding: 1.5rem;
  }
}

.auth-layout,
.basic-layout {
  .mat-mdc-card {
    padding: 40px 45px 30px;

    &:before {
      content: '';
      width: 100%;
      height: 30px;
      border-radius: 10px;
      position: absolute;
      top: -10px;
      background: rgba(255, 255, 255, 0.6);
      left: 0;
      transform: scale(0.95);
      -webkit-transform: scale(0.95);
      -ms-transform: scale(0.95);
      z-index: 0;
    }

    .mat-mdc-card-title {
      text-align: center;
    }

    .mat-mdc-form-field,
    .mat-mdc-raised-button {
      width: 100%;
    }

    .mat-mdc-raised-button {
      margin-top: 15px;
    }
  }

  .logo {
    min-width: 120px;
    width: 65%;
    max-width: 260px;
    margin: 30px 0;
  }
}

.mat-mdc-card {
  border-radius: 0.5rem !important;
}

.mat-mdc-dialog-container {
  max-height: 100vh !important;
}

@media (max-width: 599px) {
  .authenticated-layout {
    padding-top: 56px;
  }
}
